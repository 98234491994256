import React from "react";

interface AuditLogModalBodyProps {
  auditLogData: any;
}

const AuditLogBody: React.FC<AuditLogModalBodyProps> = ({ auditLogData }) => {
  return (
    <>
      {auditLogData?.diffData.length >= 1 ? (
        <table className="table table-borderless  mb-0">
          <thead>
            <tr>
              <th scope="col" className="modal-row-desc"></th>
              <th scope="col"></th>
              <th scope="col" className="modal-old-new">
                Old Value
              </th>
              <th scope="col" className="modal-old-new">
                New Value
              </th>
            </tr>
          </thead>
          <tbody>
            {auditLogData?.diffData?.map((d: any, index: number) => {
              return (
                <tr key={index} className="activity-log-modal">
                  <th scope="row" className="label-keys modal-row-desc">
                    {d?.key}
                  </th>
                  <td>:</td>
                  <td className="modal-old-new">
                    {d?.previousData?.replace(/['"]+/g, "") || "N/A"}
                  </td>
                  <td className="modal-old-new">
                    {d?.currentData?.replace(/['"]+/g, "") || "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <span> No Changes</span>
      )}
    </>
  );
};
export default AuditLogBody;
