export const AUDIT_LOG_CONSTANTS = {
  GROUPSLIST: ["classification", "degrees", "specialties","certifications"],

  AUDIT_LOG_PROGRAM_PRIORITY_LIST: [
    "programName",
    "programType",
    "programSubType",
    "programStartDate",
    "programEndDate",
    "programExpiryDate",
  ], //Programs -> Program

  SESSION_PROGRAM_LIST: [
    "programName",
    "programType",
    "programSubType",
    "programStartDate",
    "programEndDate",
    "programExpiryDate",
    "isSession",
    "totalSessionCredit",
  ],

  AUDIT_LOG_ADDRESS_PRIORITY_LIST: [
    "location",
    "line1",
    "line2",
    "city",
    "state",
    "countryCode",
    "pinCode",
  ], //Programs -> Address

  AUDIT_LOG_ACCREDITATION_PRIORITY_LIST: [
    "accreditationProgramCode",
    "classification",
    "accreditationProgramId",
    "courseApprovalNumber",
    "educationalLevel",
    "category",
    "cehCategory",
    "cdrPerformanceIndicators",
    "cdrCpeLevel",
    "abtAccreditationCode",
    "primaryProvider",
    "providership",
    "organizations",
    "moc",
    "mocType",
    "mocClaimDate",
    "certificate",
    "cmePassport",
    "programReleaseDate",
    "accreditationExpiryDate",
    "claimPeriodWindow",
    "isPartialClaim",
    "credit",
    "min",
    "max",
    "frequency",
    "certificateVersionName",
  ], // Programs -> Accreditation

  SESSION_PRIORITY_LIST: [ 
    "sessionCode",
    "sessionName",
    "accreditationProgramId",
    "primaryProvider",
    "providership",
    "organizations",
    "sessionStartDate",
    "sessionEndDate",
    "credit",
  ],

  AUDIT_LOG_VENDOR_PRIORITY_LIST: [
    "contactName",
    "secondaryEmail",
    "name",
    "contactEmail",
    "contactDetails",
    ],

  AUDIT_LOG_SFTP_PRIORITY_LIST: [
    "ftpReportingOrganisation",
    "ftpFilenamePrefix",
    "ftpUserName",
    "ftpFolder",
    "ftpUrl",
    "ftpPort",
    "ftpNightlyIndicator",
    "ftpPreferStartTime",
    "ftpPreferEndTime",
  ],

  AUDIT_LOG_ACCREDITATION_PAGE_PRIORITY_LIST: [
    "name",
    "code",
    "credit_unit",
    "isPartial",
  ],

  AUDIT_LOG_CLASSIFICATIONS_PRIORITY_LIST: [
    "code",
    "name",
    "specialties",
    "qualifications",
  ],

  AUDIT_LOG_CERTIFICATES_PRIORITY_LIST: [
    "name",
    "code",
    "accreditationCode",
    "programType",
    "programSubType",
    "providership",
    "certificateVersionName",
  ],

  AUDIT_LOG_LEARNER_DETAILS_PRIORITY_LIST: [
    "identifier",
    "lmsIdentifier",
    "firstName",
    "lastName",
    "email",
    "mobile",
    "mobileWork",
    "institutionName",
    "repositoryId",
    "ethnicity",
  ],

  AUDIT_LOG_LEARNER_ADDRESS_PRIORITY_LIST: [
    "line1",
    "line2",
    "city",
    "countryCode",
    "state",
    "province",
    "pinCode",
  ],
} as const;

export default AUDIT_LOG_CONSTANTS;
