import React from "react";
import { useTranslation } from "react-i18next";
import DeleteButtonWrapper from "./checkbox.styled";
import ModalPopupComp from "components/ModalPopup";

type inpElm = React.HTMLAttributes<HTMLInputElement>;

interface IPagination {
  pageNumber: number;
  pageOffset: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  isFirst: number;
  isLast: number;
}

interface InputProps extends inpElm {
  text?: string;
  className?: string;
  checkBxList?: number[];
  setCheckBxList?: Function;
  indeterminate?: boolean;
  data?: Array<any>;
  paginations?: IPagination;
  deleteRecords?: Function;
  MsgBody?: Function;
}

const DeleteBtn: React.FC<{ checkBxList: number[] }> = ({ checkBxList }) =>{
  const { t } = useTranslation();
  return (
    <DeleteButtonWrapper>
      <aui-button
        variant="primary"
        size="small"
        data-testid="deleteAll"
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
        data-target="#modal_backbtn">
        {t<string>("deleteAll")}{" "}
        {checkBxList.length > 0 && "(" + checkBxList.length + ")"}
      </aui-button>
    </DeleteButtonWrapper>
  );
};

const SelectAll = (props: InputProps) => {
  const { t } = useTranslation();
  const selectAllId = "selectAll";

  const {
    text = "",
    id = 0,
    className,
    checkBxList = [],
    setCheckBxList = () => {
      ("");
    },
    data = [],
    paginations,
    indeterminate = true,

    MsgBody = () => null,
  } = props;

  const key: any = id;
  const cls = (className ?? "") + " form-group form-check-bordered";
  const prefix = "chkbox_";

  const changeHandler = (e: any) => {
    if (e?.target?.checked) {
      if (key === selectAllId) {
        const temp: Array<any> = selectCurrentPageRecords();

        setCheckBxList((list: Array<number>) => {
          //@ts-ignore
          return [...new Set([...list, ...temp]).values()];
        });

        return;
      }

      setCheckBxList((list: Array<number>) => [...list, key]);
      return;
    }

    if (key === selectAllId) {
      const temp: Array<any> = selectCurrentPageRecords();
      setCheckBxList((list: Array<number>) => {
        //@ts-ignore
        const oldList = [...new Set([...list]).values()];
        return oldList.filter((idx) => temp.indexOf(idx) === -1);
      });

      return;
    }

    setCheckBxList((list: Array<number>) => {
      const ind = list?.indexOf(key);
      const tempList = [...list];
      tempList.splice(ind, 1);
      return [...tempList];
    });
  };

  const selectCurrentPageRecords = () => {
    const temp = [];

    if (paginations) {
      for (let i = 0; i < paginations.pageSize; i++) {
        if (data[i]) {
          temp.push(data[i]?.id);
        } else {
          return temp;
        }
      }
    }
    return temp;
  };

  const hasChecked = (keyUnq: number | string) => {
    if (keyUnq === selectAllId && paginations) {
      return checkBxList?.length >= data.length && data.length !== 0;
    }

    return checkBxList?.includes(+keyUnq);
  };


  return (
    <div className={cls}>
      <span>
        <input
          type="checkbox"
          value={""}
          onChange={changeHandler}
          id={prefix + id}
          checked={hasChecked(key)}
          aria-label={prefix + id}
        />
        <label className="mb-0 select-all" htmlFor={prefix + id}>
          {(hasChecked(key) || checkBxList.length > 1) && key === selectAllId
            ? <DeleteBtn checkBxList={checkBxList} />
            : text}
        </label>
      </span>
      {props.children}
    </div>
  );
};

export default SelectAll;
