import React from "react";

interface VendorLogModalBodyProps {
  vendorLogErrorData: any;
}
const VendorLogModalBody: React.FC<VendorLogModalBodyProps> = ({
  vendorLogErrorData,
}) => {
  return (
    <>
      {vendorLogErrorData?.errorData?.length >= 1 ? (
        <ol className="d-flex flex-column align-items-start aui-darkred ml-2">
          {vendorLogErrorData?.errorData?.map((d: any, index: number) => {
            return (
              <li key={index} className="mt-2">
                {d}
              </li>
            );
          })}
        </ol>
      ) : (
        <span>No Errors</span>
      )}
    </>
  );
};
export default VendorLogModalBody;
