import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useMemo,
} from "react";

export interface JSONDiffContextValue {
  diffData?: any;
  delta?: boolean;
  currData: any;
  prevData: any;
}

const JSONDiffContext = createContext<JSONDiffContextValue | null>(null);

export interface JSONDiffProviderProps {
  children: ReactNode;
  value: Partial<JSONDiffContextValue>;
}

export const JSONDiffProvider: FunctionComponent<JSONDiffProviderProps> = ({
  children,
  value,
}) => {
  const ContextValue = useMemo(
    () => ({
      ...value,
      prevData: value.prevData,
      currData: value.currData,
    }),
    [value.prevData, value.currData]
  );
  return (
    <JSONDiffContext.Provider value={ContextValue}>
      {children}
    </JSONDiffContext.Provider>
  );
};

export class JSONDiffContextError extends Error {
  constructor() {
    super("JSONDiffContext must be used within JSONDiffContextProvider");
  }
}

export const useJSONDiff = () => {
  const value = useContext(JSONDiffContext);

  if (!value) {
    throw new JSONDiffContextError();
  }

  return value;
};
